import { Controller } from '@hotwired/stimulus'

/**
 * body に用意した global な名前空間に対して操作を行える Stimulus Controller
 */
export class CvButtonController extends Controller {
  /**
   * スクロールされた時にCVボタンを表示する
   */
  onScroll () {
    if (window.scrollY > 200) {
      this.cvbuttonTarget.classList.add('cvbutton--active')
      this.cvbuttonTarget.classList.remove('cvbutton--inactive')
    } else {
      this.cvbuttonTarget.classList.remove('cvbutton--active')
      this.cvbuttonTarget.classList.add('cvbutton--inactive')
    }
  }
}

CvButtonController.targets = ['cvbutton']
